import { RECORD_TYPES } from '@emobg/motion-ui';
import { PermissionLink } from '@/components';
import { FLEET_PERMISSIONS } from '../../../const/permissions';
import fleetRoutes from '../../../router/FleetRouterMap';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: fleetRoutes.chargePostModels.detail,
          params: {
            chargePostModelUuid: result.uuid,
          },
        },
        linkPermissions: [FLEET_PERMISSIONS.viewCarsharingVehicles],
        text: result.id,
      }),
      minWidth: 100,
    },
    {
      attributeName: 'manufacturer',
      title: 'Manufacturer',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'name',
      title: 'Model',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'charge_points',
      title: 'Charge point sockets',
      displayPriority: 1,
      minWidth: 150,
    },
  ];
}
