<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiInputText, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent, StoreNotificationComponent } from '@/components';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import { scopes as CHARGE_POST_MODEL_SCOPES } from '../store/ChargePostModelsModule';

export default {
  name: 'ChargePostModelForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    chargePostModelUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      originalInputs: null,
      inputs: {
        manufacturer: null,
        name: null,
        chargePoints: null,
      },
    };
  },
  computed: {
    modalTitle() {
      if (this.isInitialized) {
        return this.chargePostModelUuid
          ? `Edit model ${get(this, 'chargePostModel.manufacturer')} ${get(this, 'chargePostModel.name')} (Id ${get(this, 'chargePostModel.id')})`
          : 'Add model';
      }

      return '';
    },
    areRequiredFieldsFilled() {
      return this.inputs.manufacturer && this.inputs.name && this.inputs.chargePoints && this.isFormValid;
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    ...mapState(DOMAINS_MODEL.fleet.chargePostModels, {
      newModelStatus: state => state.newModel.STATUS,
      newModel: state => state.newModel.data,
      chargePostModel: state => state.detail.data,
    }),
  },
  async created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.CHARGE_POST_MODEL_SCOPES = CHARGE_POST_MODEL_SCOPES;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;

    if (this.chargePostModelUuid) {
      await this.getChargePostModel(this.chargePostModelUuid);
      this.inputs.manufacturer = get(this, 'chargePostModel.manufacturer');
      this.inputs.name = get(this, 'chargePostModel.name');
      this.inputs.chargePoints = get(this, 'chargePostModel.chargePoints');
      this.originalInputs = cloneDeep(this.inputs);
    }
    this.isInitialized = true;
  },
  methods: {
    async request() {
      this.clearErrors();
      const request = this.chargePostModelUuid ? this.putChargePostModel : this.postChargePostModel;
      const params = this.chargePostModelUuid ? { chargePostModelUuid: this.chargePostModelUuid, data: this.inputs } : this.inputs;
      await request(params);

      if (!this.newModelStatus.ERROR) {
        const { id } = this.newModel;
        const message = this.chargePostModelUuid
          ? 'Model edited successfully!'
          : `Model added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback(id);
        this.resetData();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.chargePostModels, [
      'getChargePostModel',
      'postChargePostModel',
      'putChargePostModel',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.chargePostModels, [
      'resetData',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    :size="SIZES.small"
    class="ChargePostModelForm"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.chargePostModels"
        :scope="CHARGE_POST_MODEL_SCOPES.newModel"
        :is-editing="!!chargePostModelUuid"
        element="model"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Manufacturer*
            </label>
            <MuiInputText
              v-if="isInitialized"
              v-model="inputs.manufacturer"
              placeholder="Enter a manufacturer name"
              name="manufacturer"
              class="w-100 mb-4"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />
          </div>
          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Model name*
            </label>
            <MuiInputText
              v-if="isInitialized"
              v-model="inputs.name"
              placeholder="Enter a model name"
              name="name"
              class="w-100 mb-4"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />
          </div>
          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Number of charge point sockets*
            </label>
            <MuiInputText
              v-if="isInitialized"
              v-model.number="inputs.chargePoints"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              placeholder="Enter a number value for charge points"
              class="w-100"
              name="chargePoints"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton @click="$emit('closeModal')" />
        </div>

        <div class="d-flex justify-content-center">
          <ui-button
            :disabled="!areRequiredFieldsFilled || hasSameValues"
            :loading="newModelStatus.LOADING"
            class="wmin-initial"
            @clickbutton="request"
          >
            Save
          </ui-button>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
  .ChargePostModelForm {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    input[type="number"] {
      width: 100%;
      appearance: initial;
    }
  }
</style>
