<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    chargePostModel: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    text() {
      return `${get(this, 'chargePostModel.manufacturer')} ${get(this, 'chargePostModel.name')} (Id ${get(this, 'chargePostModel.id')})`;
    },
    ...mapState(DOMAINS_MODEL.fleet.chargePostModels, {
      deleteModelStatus: state => state.deleteModel.STATUS,
    }),
  },
  methods: {
    async removeChargePostModel() {
      await this.deleteChargePostModel(this.chargePostModel.uuid);
      this.$emit('closeModal');
      if (!this.deleteModelStatus.ERROR) {
        this.$notify({
          message: 'Model deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.chargePostModels, [
      'deleteChargePostModel',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteVehicleModel"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="text"
        title="Are you sure you want to delete this model?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteModelStatus.LOADING"
        @click="removeChargePostModel"
      />
    </template>
  </GenericModalComponent>
</template>
