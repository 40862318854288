var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ChargePostModelsListView" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Charge post models "),
          ]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create_charge_post_model-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Create new charge post model ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "chargePostModel",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              labels: {
                searchTable: "Search by manufaturer, model, etc.",
              },
              "table-config": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.chargePostModels,
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("ChargePostModelForm", {
            attrs: {
              "charge-post-model-uuid": _vm.uuidToEdit,
              callback: _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteChargePostModel", {
            attrs: {
              "charge-post-model": _vm.chargePostModel,
              callback: _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }