<script>
import { mapActions, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';

import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';
import ChargePostModelForm from '../components/ChargePostModelForm';

export default {
  name: 'ChargePostModelsDetailView',
  components: {
    EditButton,
    ContentCellComponent,
    ChargePostModelForm,
  },
  props: {
    chargePostModelUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isChargePostModelFormOpened: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.chargePostModels, {
      chargePostModel: state => state.detail.data,
    }),
  },
  watch: {
    chargePostModelUuid: {
      async handler() {
        await this.fetchChargePostModel();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.chargePostModels, [
      'getChargePostModel',
    ]),
    async fetchChargePostModel() {
      this.isLoading = true;
      await this.getChargePostModel(this.chargePostModelUuid);
      this.isLoading = false;

      if (!this.chargePostModel) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }
    },
    async chargePostUpdated() {
      this.isChargePostModelFormOpened = false;
      await this.fetchChargePostModel();
    },
  },
};
</script>

<template>
  <div
    :class="[
      'ChargePostModelsDetailView d-flex flex-column flex-fill',
      {
        'justify-content-center': isLoading,
      }
    ]"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading charge post model..."
    />
    <template v-else-if="chargePostModel">
      <h2 class="mb-2">
        {{ chargePostModel.manufacturer }} {{ chargePostModel.name }}
      </h2>
      <ui-card
        header="Charge post model information"
        class="d-block"
      >
        <div class="row">
          <div class="col-10">
            <div class="row mb-n4">
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePostModel.id"
                  label="Id"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePostModel.manufacturer"
                  label="Manufacturer"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePostModel.name"
                  label="Model name"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePostModel.chargePoints"
                  label="Charge point sockets"
                />
              </div>
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <EditButton
              class="mb-4"
              @click="isChargePostModelFormOpened = true"
            />
          </div>
        </div>
      </ui-card>
      <ChargePostModelForm
        v-if="isChargePostModelFormOpened"
        :charge-post-model-uuid="chargePostModelUuid"
        :callback="chargePostUpdated"
        @closeModal="isChargePostModelFormOpened = false"
        @modal-closed="isChargePostModelFormOpened = false"
      />
    </template>
  </div>
</template>
