<script>
import { PageView } from '@/components';

export default {
  name: 'ChargePostModelsView',
  components: { PageView },
};
</script>

<template>
  <PageView class="ChargePostModelsView d-flex flex-column flex-fill">
    <RouterView />
  </PageView>
</template>

