export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Manufacturer',
      attributeName: 'manufacturer',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Model',
      attributeName: 'name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Charge point sockets',
      attributeName: 'charge_points',
    },
  },
];
