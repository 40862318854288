var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ChargePostModelForm",
        attrs: {
          title: _vm.modalTitle,
          header: { isClosable: true },
          size: _vm.SIZES.small,
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.fleet.chargePostModels,
              scope: _vm.CHARGE_POST_MODEL_SCOPES.newModel,
              "is-editing": !!_vm.chargePostModelUuid,
              element: "model",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "d-block emobg-font-weight-semibold mb-1",
                      },
                      [_vm._v(" Manufacturer* ")]
                    ),
                    _vm.isInitialized
                      ? _c("MuiInputText", {
                          staticClass: "w-100 mb-4",
                          attrs: {
                            placeholder: "Enter a manufacturer name",
                            name: "manufacturer",
                          },
                          model: {
                            value: _vm.inputs.manufacturer,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "manufacturer", $$v)
                            },
                            expression: "inputs.manufacturer",
                          },
                        })
                      : _c("ui-skeleton", { staticClass: "mb-4" }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "d-block emobg-font-weight-semibold mb-1",
                      },
                      [_vm._v(" Model name* ")]
                    ),
                    _vm.isInitialized
                      ? _c("MuiInputText", {
                          staticClass: "w-100 mb-4",
                          attrs: {
                            placeholder: "Enter a model name",
                            name: "name",
                          },
                          model: {
                            value: _vm.inputs.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "name", $$v)
                            },
                            expression: "inputs.name",
                          },
                        })
                      : _c("ui-skeleton", { staticClass: "mb-4" }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "d-block emobg-font-weight-semibold mb-1",
                      },
                      [_vm._v(" Number of charge point sockets* ")]
                    ),
                    _vm.isInitialized
                      ? _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isPattern:
                                  _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                              },
                              expression:
                                "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            placeholder:
                              "Enter a number value for charge points",
                            name: "chargePoints",
                          },
                          model: {
                            value: _vm.inputs.chargePoints,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "chargePoints", _vm._n($$v))
                            },
                            expression: "inputs.chargePoints",
                          },
                        })
                      : _c("ui-skeleton", { staticClass: "mb-4" }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled:
                        !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                      loading: _vm.newModelStatus.LOADING,
                    },
                    on: { clickbutton: _vm.request },
                  },
                  [_vm._v(" Save ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }