var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "ChargePostModelsDetailView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading charge post model..." } })
        : _vm.chargePostModel
        ? [
            _c("h2", { staticClass: "mb-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.chargePostModel.manufacturer) +
                  " " +
                  _vm._s(_vm.chargePostModel.name) +
                  " "
              ),
            ]),
            _c(
              "ui-card",
              {
                staticClass: "d-block",
                attrs: { header: "Charge post model information" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-10" }, [
                    _c("div", { staticClass: "row mb-n4" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-6 col-md-3 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.chargePostModel.id,
                              label: "Id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-6 col-md-3 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.chargePostModel.manufacturer,
                              label: "Manufacturer",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-6 col-md-3 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.chargePostModel.name,
                              label: "Model name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-6 col-md-3 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.chargePostModel.chargePoints,
                              label: "Charge point sockets",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col d-flex justify-content-end" },
                    [
                      _c("EditButton", {
                        staticClass: "mb-4",
                        on: {
                          click: function ($event) {
                            _vm.isChargePostModelFormOpened = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm.isChargePostModelFormOpened
              ? _c("ChargePostModelForm", {
                  attrs: {
                    "charge-post-model-uuid": _vm.chargePostModelUuid,
                    callback: _vm.chargePostUpdated,
                  },
                  on: {
                    closeModal: function ($event) {
                      _vm.isChargePostModelFormOpened = false
                    },
                    "modal-closed": function ($event) {
                      _vm.isChargePostModelFormOpened = false
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }