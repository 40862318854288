<script>
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { refreshAlgoliaStore } from '@/utils/algolia';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentCells,
  facets,
} from './config/index';
import ChargePostModelForm from '../components/ChargePostModelForm';
import DeleteChargePostModel from '../components/DeleteChargePostModel';

export default {
  name: 'ChargePostModelsListView',
  components: {
    DeleteChargePostModel,
    MuiAlgoliaList,
    ChargePostModelForm,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isDeleteModalVisible: false,
      uuidToEdit: null,
      chargePostModel: null,
    };
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    const element = 'model';
    this.contentCells = contentCells();
    this.exportColumns = this.contentCells;
    this.facets = facets;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: ({ uuid }) => {
          this.uuidToEdit = uuid;
          this.isModalVisible = true;
        },
      },
      {
        label: `Delete ${element}`,
        type: 'danger',
        class: 'emobg-color-danger',
        method: model => {
          this.chargePostModel = camelCaseKeys(model);
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.uuidToEdit = null;
      this.chargePostModel = null;
    },
    onFormSuccess() {
      this.closeModal();
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.chargePostModel, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <div class="ChargePostModelsListView">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Charge post models
      </h1>
      <ui-button
        data-test-id="create_charge_post_model-button"
        @clickbutton="isModalVisible = true"
      >
        Create new charge post model
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="chargePostModel"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :labels="{
          searchTable: 'Search by manufaturer, model, etc.',
        }"
        :table-config="contentCells"
        :index="ALGOLIA_INDEXES.chargePostModels"
        is-export-enabled
      />
    </div>
    <ChargePostModelForm
      v-if="isModalVisible"
      :charge-post-model-uuid="uuidToEdit"
      :callback="onFormSuccess"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
    <DeleteChargePostModel
      v-if="isDeleteModalVisible"
      :charge-post-model="chargePostModel"
      :callback="onFormSuccess"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
  </div>
</template>

